<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nuevo departamento</h1>
        <hr class="">
      </v-col>
      <v-form ref="form" @submit.prevent="submitForm">
        <v-row>
          <v-col cols="12" class="mt-10">
            <v-text-field
                v-model="data.name"
                label="Nombre"
                placeholder="Nombre"
                :rules="[rules.required]"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn type="submit" color="primary" style="width: 200px">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";

export default {
  name: "CreateDepartments",
  data() {
    return {
      overlay: false,
      data: {
        name: "",
      },
      rules: {
        required: value => !!value || 'Este campo es requerido.',
      },
    };
  },
  components: {
    Overlay
  },
  methods: {
    ...mapActions('users', ['createDepartments']),
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        const response = await this.createDepartments(this.data);
        if (!response.error) {
          this.overlay = true;
          this.sweetAlertSuccess('Se creo correctamente')
          await this.$router.push('/departamentos')
        } else {
          this.overlay = false;
          this.sweetAlertError(response.error)
        }
      }
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
